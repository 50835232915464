.key-value-pair {
    display: flex;
    flex-direction: column;
    border: 1px solid #e8e8e8;
    padding: 16px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.05);
  }
  
  .key {
    font-weight: bold;
  }
  
  .value {
    margin-top: 8px;
  }
  /* ScrollableContainer.css */

.scrollablegeneral-container {
  /* width: 300px; Adjust width as needed */
  /* height: 10px; Adjust height as needed */
  overflow-y: auto; 
  overflow-x: hidden;
  /* Enable vertical scrollbar */
  border: transparent;
  /* border-radius: 15px; */
}

.content {
  /* padding: 10px; Optional: Add padding for content */
}

/* Style the scrollbar */
.scrollablegeneral-container::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
}

.scrollablegeneral-container::-webkit-scrollbar-track {
  background:transparent; /* Track color */
}

.scrollablegeneral-container::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 4px;
}

.scrollablegeneral-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover color */
}

  /* Adjust styling as needed */
  