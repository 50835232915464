.custom-form-item {
    margin-bottom: 0 !important;
  }
  
  .custom-radio-group {
    margin-bottom: 0 !important;
  }
  .datepicker-dropdown {
    max-height: 150px;
    /* overflow-y: auto; */
  }
  .ant-picker-time-panel {
    max-height: 225px; /* Adjust the height as needed */
    overflow-y: auto; /* Enable scroll if the content exceeds the height */
  }
  
  .ant-picker-time-panel-column {
    height: 100%; /* Ensure each column fills the height of the panel */
  }