/* Custom CSS to target Collapse header */
.ant-collapse-item .ant-collapse-header {
    padding: 0.8rem 0rem 0.8rem 0.7rem !important;
    margin: 0 !important;
}

/* Optionally target inner elements for additional control */
.ant-collapse-item .ant-collapse-header h2 {
    margin: 0; /* Reset margin for specific header elements */
}
