.inputclassforflight {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  width: 95%;
}

.input-container {
  position: relative;
  margin-top: 0.5rem;
}

.svg-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-80%);
  cursor: pointer;
}

.label-container {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.inputclassforflight::placeholder {
  color:#d9d9d9; /* Replace with the desired color */
  font-weight: 100;
}
.inputclassforflight:focus {
  outline: none; /* Remove the default focus outline */
  border-color: #007bff; /* Blue border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Box shadow on focus */
}
.inputclassforflight:hover {
  border-color: #007bff; /* Blue border color on hover */
}
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-spinner {
  -moz-appearance: textfield;
  appearance: textfield;
}