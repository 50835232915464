
.react-datepicker__input-container input {
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  height: 15px;
}

.react-datepicker__input-container input::placeholder {
  color:#d9d9d9; /* Replace with the desired color */
  font-weight: 100;
}
.react-datepicker {
  font-family: Arial, sans-serif;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  position: relative;
}

.react-datepicker__calendar-container {
  position: absolute;
  top: 40px; 
  left: 0;
  z-index: 1000;
}
.react-datepicker__input-container input:hover {
  border-color: #007bff; /* Blue border color on hover */
}

/* Focus (click) state */
.react-datepicker__input-container input:focus {
  outline: none; /* Remove the default focus outline */
  border-color: #007bff; /* Blue border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Box shadow on focus */
}
.inputclass{
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  height: 15px;
}
.inputclass::placeholder {
  color:#d9d9d9; /* Replace with the desired color */
  font-weight: 100;
}
.inputclass:focus {
  outline: none; /* Remove the default focus outline */
  border-color: #007bff; /* Blue border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Box shadow on focus */
}
.inputclass:hover {
  border-color: #007bff; /* Blue border color on hover */
}