.inputclasscredit {
  width: 100px;
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 14px;
  border-radius: 4px;
  height: 15px;
}

.inputclasscredit::placeholder {
  color: #d9d9d9; 
  font-weight: 100;
}

.inputclasscredit:hover {
  border-color: #007bff; /* Blue border color on hover */
}

.inputclasscredit:focus {
  outline: none; /* Remove the default focus outline */
  border-color: #007bff; /* Blue border color on focus */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Box shadow on focus */
}
