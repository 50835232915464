.scrollable-container-x {
    overflow-y: auto; 
    overflow-x: auto;
    border: transparent;
}

/* Reduce the height of the x-axis scrollbar */
.scrollable-container-x::-webkit-scrollbar {
    height: 8px; /* Adjust this value to reduce the height of the horizontal scrollbar */
    width: 8px; /* This will maintain the width for the vertical scrollbar */
}

.scrollable-container-x::-webkit-scrollbar-track {
    background: transparent; 
}

.scrollable-container-x::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px; 
}

.scrollable-container-x::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
