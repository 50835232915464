/* FaqSearch.css */
.faq-container {
    /* max-width: 800px; */
    margin-top: 2rem;
    margin: 2rem 1rem 1rem 1rem;
    padding: 0.5rem;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    height: 73vh;
  }
  
  .answer-container {
    /* margin-top: 2rem; */
    margin: 1rem 1rem 1rem 0rem;
    padding: 1.5rem;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    height: 30vh;
  }
  
  h1 {
    text-align: center;
    color: #1890ff;
  }
