/* Custom CSS for the scrollable menu */
.menu-container {
    width: 100%; /* Adjust width as needed */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Hide vertical scrollbar */
    max-height: 200px; /* Set a maximum height if necessary */
  }
  
  .menu-container::-webkit-scrollbar {
    width: 4px; /* Adjust scrollbar width (thinner scrollbar) */
  }
  
  .menu-container::-webkit-scrollbar-track {
    background: transparent; /* Track color */
  }
  
  .menu-container::-webkit-scrollbar-thumb {
    background-color: #ccc; /* Scrollbar thumb color */
    border-radius: 4px; /* Rounded corners */
  }
  
  .menu-container::-webkit-scrollbar-thumb:hover {
    background-color: #999; /* Hover color for scrollbar thumb */
  }
  