.cardsexpense-container {
    display: flex;
    flex-direction: row; /* Stack cards vertically */
    gap: 20px; /* Add some space between cards */
    justify-content: center;
    padding: 13vh 1rem 0 0;
  }
  
  .cardexpense {
    border: none;
    background-color: #fff;
    /* border: 1px solid #ccc; */
    border-radius: 15px;
    overflow: hidden;
    max-width: 300px;
    cursor: pointer;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  
  .cardexpense-image {
    width: 100%;
    height: auto;
  }
  
  .cardexpense-content {
    padding: 20px;
  }
  
  .cardexpense-title {
    font-size: 1rem;
    margin-top: 0;
    color: #1B2559;
    text-align: center;
  }
  
  .cardexpense-text {
    font-size: 1rem;
    color: #666;
  }
  