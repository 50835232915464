

.scroll-container-pull {
    /* Set the height and overflow properties to enable scrolling */
    overflow-y: scroll; /* Enable vertical scrolling */
  
    /* Hide the default scrollbar in WebKit browsers */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  
    /* Hide the default scrollbar in Firefox */
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
  
    /* Customize the appearance of the scrollbar for WebKit browsers */
    &::-webkit-scrollbar {
      width: 4px; /* Adjust the width as needed */
      height: 6px;
    }
  
    &::-webkit-scrollbar-thumb {

      background-color:#F3EEEA ; /* Thumb color */
      border-radius: 4px; /* Rounded corners for the thumb */
      height: 0.5px; /* Adjust the height as needed */
    }
  
    &::-webkit-scrollbar-track {
      background-color:white; /* Track color */
      border-radius: 15px;
    }
  }
  