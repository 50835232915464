/* ScrollableContainer.css */

.scrollable-container {
    /* width: 300px; Adjust width as needed */
    /* height: 10px; Adjust height as needed */
    overflow-y: auto; 
    overflow-x: hidden;
    /* Enable vertical scrollbar */
    border: transparent;
    border-radius: 15px;
  }
  

  /* Style the scrollbar */
  .scrollable-container::-webkit-scrollbar {
    width: 8px; /* Adjust scrollbar width */
  }
  
  .scrollable-container::-webkit-scrollbar-track {
    background:transparent; /* Track color */
  }
  
  .scrollable-container::-webkit-scrollbar-thumb {
    background: #888; /* Thumb color */
    border-radius: 4px; /* Rounded corners */
  }
  
  .scrollable-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Hover color */
  }
  