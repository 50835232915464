/* YourComponent.css */

/* Custom styling for scroll bars */
.custom-scrollbar {
    /* For WebKit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 12px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #1890ff; /* Customize the color as needed */
      border-radius: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background-color: #f0f0f0; /* Customize the color as needed */
      border-radius: 6px;
    }
  
    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: #1890ff #f0f0f0; /* Customize the colors as needed */
  }
  