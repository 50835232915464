.btn{
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    gap: 15px;
    width: 121px;
    height: 38px;
    background-color: #F0F2F5;
    border-radius: 24px;
    color: #9AA4B5;
    border: none;
    padding: 0;
    margin: 0;
}
.btn.click{
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    gap: 15px;
    width: 121px;
    height: 38px;
    background-color: #344767;
    border-radius: 24px;
    color: white;
    border: none;
    padding: 0;
    margin: 0;
}