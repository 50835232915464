.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    width: 80px; /* Adjust the size as needed */
    height: 80px; /* Adjust the size as needed */
    animation: rotateY 2s linear infinite;
    justify-content: center;
  }
  
  @keyframes rotateY {
    /* 0% {
      transform: rotateY(0deg);
    } */
    100% {
      transform: rotateY(360deg);
    }
  }
  
  
